<template>
  <v-navigation-drawer v-model="$store.state.drawer" id="core-navigation-drawer" app width="200" fill-height>
    <v-list nav fill-height v-show="!loading">
      <router-link :to="{ name: item.to, params: item.params }" v-for="(item, i) in filteredMenu" :key="i"
                   :class="{'v-item--active v-list-item--active':  Object.keys(item.params).length ? ($route.params.collection == item.params.collection) : item.activator.includes($route.name), 'grey--text': Object.keys(item.params).length ? ($route.params.collection != item.params.collection) : !item.activator.includes($route.name)}"
                   tag="v-list-item" class="text-center d-flex justify-content-center mt-1">
        <router-link :to="{ name: item.to, params: item.params }" tag="v-row" class="pa-4">
          <v-row align="center" justify="center">
            <v-col cols="12" align="center" justify="center" class="mb-0 pb-0">
              <v-icon>{{ item.icon }}</v-icon>
            </v-col>
            <v-col cols="12" align="center" justify="center" class="pt-1 mt-0">
              <span class="main-font font-11">{{ item.title }}</span>
            </v-col>
          </v-row>
        </router-link>
      </router-link>
      <v-list-item class="text-center d-flex justify-content-center mt-1" v-if="$helpers.isMobile()" @click="logout">
        <v-row class="pa-4">
          <v-row align="center" justify="center">
            <v-col cols="12" align="center" justify="center" class="mb-0 pb-0">
              <v-icon>mdi-logout</v-icon>
            </v-col>
            <v-col cols="12" align="center" justify="center" class="pt-1 mt-0">
              <span class="main-font font-11">{{ $t('logout') }}</span>
            </v-col>
          </v-row>
        </v-row>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

import store from "../../../../store";

export default {
  name: "DashboardCoreDrawer",

  data() {
    let showForUser = !this.$store.getters.userIsLoggedInAsTrustedUser
    let showForAdmin = !(this.$store.getters.adminIsLoggedInAsUser && store.getters.adminRole === 'alleato Admin')
    return {
      items: [
        {
          title: this.$t('profile'),
          icon: "mdi-account-outline",
          to: "profile",
          params: {},
          activator: ['profile', 'keys', 'profileInfo', 'profilePassword', 'profilePlans', 'profileNotifications'],
          show: showForUser && showForAdmin
        },
        {
          title: this.$t('checkliste'),
          icon: "mdi-format-list-checks",
          to: "checklist",
          params: {},
          activator: ['checklist'],
          show: showForUser && showForAdmin
        },
        {
          icon: "mdi-email-outline",
          title: this.$t('botschaften'),
          to: "mediaIndex",
          params: {collection: 'botschaften', type: 'images'},
          activator: [],
          show: showForAdmin
        },
        {
          icon: "mdi-file-account-outline",
          title: this.$t('personaldocs'),
          to: "personaldocsIndex",
          params: {},
          activator: ['personaldocsIndex', 'personaldocsList'],
          show: showForAdmin
        },
        {
          icon: "mdi-file-document-multiple-outline",
          title: this.$t('documenttemps'),
          to: "documenttempsIndex",
          params: {},
          activator: ['documenttempsIndex', 'documenttempsList'],
          show: showForUser && showForAdmin
        },
        {
          icon: "$alleato",
          title: this.$t('advisor'),
          to: "advisorIndex",
          params: {},
          activator: ['advisorIndex', 'advisorList'],
          show: true
        },
        {
          icon: 'mdi-percent-outline',
          title: this.$t('benefit_partners'),
          to: "benefitpartnersIndex",
          params: {},
          activator: ['benefitpartnersIndex', 'benefitpartnersList'],
          show: showForUser && showForAdmin
        },
        {
          title: this.$t('trusted_menu'),
          icon: "mdi-account-multiple-outline",
          to: "trustedUsers",
          params: {},
          activator: ['trustedUsers', 'trustedUsersList', 'entrustedUsersList'],
          show: showForUser && showForAdmin
        },
        {
          icon: "mdi-forum-outline",
          title: this.$t('wunsch'),
          to: "lastwishIndex",
          params: {},
          activator: ['lastwishIndex', 'lastwishForm'],
          show: showForAdmin
        },
        {
          title: this.$t('tresor'),
          icon: "mdi-shield-check-outline",
          to: "tresor",
          params: {},
          activator: ['tresor', 'tresorIndex', 'newTresor'],
          show: showForAdmin
        },
        {
          title: this.$t('support'),
          icon: "mdi-message-text-outline",
          to: "support",
          params: {},
          activator: ['support', 'supportIndex', 'showSupport', 'newSupport'],
          show: showForUser && showForAdmin
        }
      ],
      loading: false
    }
  },
  computed: {
    filteredMenu() {
      return this.items.filter(item => {
        return item.show
      })
    }
  },
  mounted() {
    this.getMe()
  },
  methods: {
    getMe() {
      this.loading = true
      this.$store.dispatch('getMe')
          .then(resp => {
            this.loading = false
          })
    },
    logout() {
      this.$store.dispatch('logout')
          .then(resp => {
            this.$store.commit('clear_user_imitation')
            this.$router.go({name: 'home'})
          })
    }
  }
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item

  &__icon--text,
  &__icon:first-child
    justify-content: center
    text-align: center
    width: 20px

    +ltr()
    margin-right: 24px
    margin-left: 12px !important

    +rtl()
    margin-left: 24px
    margin-right: 12px !important

  .v-list--dense

  .v-list-item
    &__icon--text,
    &__icon:first-child
      margin-top: 10px

  .v-list-group--sub-group

  .v-list-item
    +ltr()
    padding-left: 8px

    +rtl()
    padding-right: 8px

  .v-list-group__header
    +ltr()
    padding-right: 0

    +rtl()
    padding-right: 0

    .v-list-item__icon--text
      margin-top: 19px
      order: 0

    .v-list-group__header__prepend-icon
      order: 2

      +ltr()
      margin-right: 8px

      +rtl()
      margin-left: 8px
</style>
